export const RoutesEn = [
  {
    route: `/en`,
    title: `Home`,
    button: false,
  },
  {
    route: `/en/m-power`,
    title: `M-Power`,
    button: false,
  },
  {
    route: `/en/software-development`,
    title: `Software Development`,
    button: false,
  },
  {
    route: `/en/services`,
    title: `Services`,
    button: false,
  },
  {
    route: `/en/contact`,
    title: `Contact Us`,
    button: true,
  },
]

export const RoutesFi = [
  {
    route: `/fi`,
    title: `Etusivu`,
    button: false,
  },
  {
    route: `/fi/m-power`,
    title: `M-Power`,
    button: false,
  },
  {
    route: `/fi/ohjelmistokehitys`,
    title: `Ohjelmistokehitys`,
    button: false,
  },
  {
    route: `/fi/palvelut`,
    title: `Palvelut`,
    button: false,
  },
  {
    route: `/fi/ota-yhteytta`,
    title: `Ota yhteyttä`,
    button: true,
  },
]
