import { Link } from "gatsby"
import React from "react"
import { motion, useCycle } from "framer-motion"
import PropTypes from "prop-types"

import MenuToggle from "./MenuToggle"

const Menu = ({ items }) => {
  const [isOpen, toggleOpen] = useCycle(false, true)

  return (
    <motion.div initial={false} animate={isOpen ? "open" : "closed"}>
      <MenuToggle toggle={() => toggleOpen()} />
      {isOpen && (
        <motion.div
          className="overflow-hidden absolute right-0 mt-2 mr-4 sm:mr-8 w-2/3 rounded-md bg-white shadow-lg"
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
        >
          <div className="py-1" role="menu" aria-orientation="vertical">
            {items.map(link => (
              <Link
                className="block px-6 py-3 font-body font-light leading-5 text-gray-600 hover:text-gray-700"
                activeStyle={{ color: "var(--bittersweet)" }}
                role="menuitem"
                key={link.title}
                to={link.route}
              >
                {link.title}
              </Link>
            ))}
          </div>
        </motion.div>
      )}
    </motion.div>
  )
}

Menu.propTypes = {
  items: PropTypes.array,
}

export default Menu
