import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

import Dropdown from "./Dropdown"

import Fi from "../icons/fi.svg"
import Us from "../icons/us.svg"

const Language = ({ title }) => {
  return (
    <Dropdown title={title}>
      {[
        {
          route: `/fi`,
          title: `Suomi`,
          flag: <Fi />,
        },
        {
          route: `/en`,
          title: `English`,
          flag: <Us />,
        },
      ].map(link => (
        <Link
          className="flex px-4 py-2 leading-6 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
          role="menuitem"
          key={link.title}
          to={link.route}
        >
          <div className="rounded-full w-6 h-6 inline-block mr-4 shadow">
            {link.flag}
          </div>
          {link.title}
        </Link>
      ))}
    </Dropdown>
  )
}

Language.propTypes = {
  title: PropTypes.node.isRequired,
}

export default Language
