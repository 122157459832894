import React, { useState } from "react"
import { motion } from "framer-motion"
import PropTypes from "prop-types"

const variants = {
  closed: { rotate: 0 },
  open: { rotate: 180 },
}

const Dropdown = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="relative inline-block text-left">
      <button
        type="button"
        className="inline-flex justify-center w-full rounded-md border border-gray-600 px-4 py-2 bg-white leading-5 font-medium text-gray-800 hover:text-gray-700 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-700 transition ease-in-out duration-150"
        id="language-menu"
        aria-haspopup="true"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        <motion.svg
          animate={isOpen ? "open" : "closed"}
          variants={variants}
          className="-mr-1 ml-2 h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          />
        </motion.svg>
      </button>
      {isOpen && (
        <motion.div
          className="overflow-hidden absolute mt-2 w-56 rounded-md bg-white shadow-lg"
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
        >
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="language-menu"
          >
            {children}
          </div>
        </motion.div>
      )}
    </div>
  )
}

Dropdown.propTypes = {
  title: PropTypes.string,
  children: PropTypes.array,
}

export default Dropdown
