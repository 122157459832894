import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Button = ({ children, pill, className, ...props }) => (
  <Link
    className={`bg-orange shadow-orange text-white tracking-wider px-6 py-4 font-body rounded-md
      ${pill ? `rounded-full` : `rounded-md`}
      ${className}
    `}
    {...props}
  >
    {children}
  </Link>
)

Button.propTypes = {
  pill: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
}

export default Button
